<template>
  <div class="bg-primary">
    <div class="min-vh-100 bg-primary bg-primary-img position-relative">
      <div class="text-center">
        <img alt="Vue logo" src="@/assets/bang-bang-logo.png" class="vh-50 mt-9"/>
      </div>
      <h1 class="text-center text-dark mt-3 pb-9 pb-md-5 text-uppercase">
        <div>Взрывные эмоции</div>
        <div>доступные каждому</div>
      </h1>
    </div>

    <div class="text-primary bg-dark py-9">
      <div class="container">
        <h3 class="text-center text-primary mb-6 fs-1">Что такое лазертаг?</h3>
        <div class="row">
          <div class="col-md-6 px-3 py-2">
            <div class="m-auto">
              <img src="@/assets/img/3_2.jpg" class="w-100 opacity-6 rounded-3"/>
            </div>
          </div>
          <div class="col-md-6">
            <p class="text-primary text-justify fs-3">
              Лазертаг – это развлекательно-спортивная игра в реальном времени и пространстве.
              Без боли и синяков, но с прекрасным послевкусием ярких эмоций. Большинство в детстве играли в
              войнушку во дворе и всегда спорили: «Я убил тебя!», «Нет, промазал!». Лазертаг лишает вас этих неприятных
              споров и позволяет вынырнуть из повседневной суеты.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-dark bg-primary bg-primary-img py-9">
      <div class="container">
        <h3 class="text-center text-dark mb-6 fs-1">
          Выбирай подходящую услугу
        </h3>

        <div class="row my-5">
          <div class="col-md-8 offset-md-2">
            <img src="@/assets/img/01-2025-min.jpeg" class="border-radius-lg d-block w-100" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 my-2" v-for="(service, index) in services.filter(s => s.is_main)" v-bind:key="index">
            <a :href="'/services/' + service.page" class="card bg-primary h-100">
              <h4 class="text-center bg-transparent text-dark mt-3 fs-3 fs-md-2">
                {{ service.title }}
              </h4>
              <div class="card-body">
                <img :src="service.image" class="img-fluid border-radius-lg"/>
                <div class="mt-4 text-dark text-center">
                  <div class="d-none d-md-block text-dark text-justify text-sm">
                    {{ service.promo_description }}
                  </div>
                  <div class="mt-4">
                    <span class="del-price text-secondary">{{ service.price }} ₽</span>
                    <span class="text-dark ms-2 font-weight-bold text-lg">{{ Math.floor(service.price * (1 - service.discount / 100)) }} ₽ </span>
                    <span class="text-xs bg-gradient-danger border-radius-lg px-2">-{{ service.discount }}%</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6 my-2" v-for="(service, index) in services.filter(s => !s.is_main)" v-bind:key="index">
                <SingleService :service="service" />
              </div>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-12 text-center">
            <a href="/services" class="btn btn-dark btn-lg">
              Посмотреть все наши услуги
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="min-vh-25 text-primary bg-dark py-8">
      <div class="container">
        <h3 class="text-center text-primary mb-6 fs-1">Коротко о нас</h3>
        <div class="row">
          <div class="col-md-6">
            <ul class="list-group">
              <li class="bg-transparent border-0 fs-4 py-2">Единственная в Саратовской области двухэтажная лазертаг
                арена.
              </li>
              <li class="bg-transparent border-0 fs-4 py-2">Площадь помещения позволяет комфортно играть в любую погоду
                компаниям до 30 человек
              </li>
              <li class="bg-transparent border-0 fs-4 py-2">Богатый арсенал на любой вкус и возраст.</li>
              <li class="bg-transparent border-0 fs-4 py-2">Две просторные комнаты отдыха. Внимательное отношения к
                пожеланиям гостей.
              </li>
              <li class="bg-transparent border-0 fs-4 py-2">Удобное месторасположение в центре города. К нам легко
                добраться.
              </li>
            </ul>
          </div>
          <div class="col-md-6 px-3 py-2">
            <div class="m-auto">
              <img src="@/assets/img/about.jpg" class="w-100 opacity-10 rounded-3"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="min-vh-25 text-dark bg-primary bg-primary-img py-6">
      <div class="container">
        <h3 class="text-center text-dark mb-6 fs-1">Наша арена</h3>

        <VueAgile
          class="main_agile"
          ref="main_agile"
          :options="agile_options"
          :as-nav-for="asNavFor1"
        >
          <div
            class="slide"
            v-for="(slide, index) in slides"
            :key="index"
            :class="`slide--${index}`"
          >
            <img :src="slide" :alt="slide" class="border-radius-lg"/>
          </div>
        </VueAgile>
        <VueAgile class="thumbnails mt-2" ref="thumbnails" :options="thumbnails_options" :as-nav-for="asNavFor2">
          <div class="slide slide--thumbniail" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`"
               @click="$refs.thumbnails.goTo(index)">
            <img :src="slide" class="border-radius-lg" :alt="slide"/>
          </div>
          <template v-slot:prevButton>
            <i class="fas fa-chevron-left text-dark"></i>
          </template>
          <template v-slot:nextButton>
            <i class="fas fa-chevron-right text-dark"></i>
          </template>
        </VueAgile>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-canvas-video-player/dist/style.css";
import { VueAgile } from "vue-agile";
import { useMetaWithPage } from "@/models/metadata";
import { SITE_SERVICES } from "@/models/services";
import SingleService from "@/components/SingleService";

export default {
  name: "IndexPage",
  components: {
    SingleService,
    VueAgile,
  },
  setup() {
    useMetaWithPage("index");
  },
  data() {
    return {
      title: "Welcome to Bang Bang",
      services: [],
      asNavFor1: [],
      asNavFor2: [],
      agile_options: {
        dots: false,
        fade: true,
        navButtons: false,
      },
      thumbnails_options: {
        autoplay: true,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
            },
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
      slides: [
        require("@/assets/img/album/1.jpg"),
        require("@/assets/img/album/2.jpg"),
        require("@/assets/img/album/3.jpg"),
        require("@/assets/img/album/4.jpg"),
        require("@/assets/img/album/5.jpg"),
        require("@/assets/img/album/6.jpg"),
        require("@/assets/img/album/7.jpg"),
        require("@/assets/img/album/8.jpg"),
        require("@/assets/img/album/9.jpg"),
        require("@/assets/img/album/10.jpg"),
        require("@/assets/img/album/11.jpg"),
        require("@/assets/img/album/12.jpg"),
      ],
    };
  },
  beforeMount() {
    this.services = SITE_SERVICES;
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main_agile);
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped>

</style>
