const SITE_SERVICES = [
  {
    id: 1,
    page: "semeyniy-lazertag",
    title: "Семейный отдых с лазертагом",
    description: `Хотите приятно и активно провести время всей семьёй? Похвально! Приезжайте к нам все вместе и не забудьте позвать друзей. На равных сможете повеселиться от мала до велика и дополнительно упрочить семейный и дружеские узы.
<br /><br />
В стоимость входят:
<ul>
<li>Заботливый и внимательный персонал, который проведёт инструктаж и будет с вами на протяжении всего посещения.</li>
<li>Аренда игровой арены.</li>
<li>Прокат индивидуального лазертаг-оборудования: <a href='https://vk.com/bangbangsar?z=album-205196470_279872477'>тагер</a> на выбор + повязка с датчиками попадания.</li>
<li>2 часа захватывающих эмоций.</li>
<li>Просторная и уютная комната отдыха.</li>
</ul>`,
    promo_description:
      "Хотите приятно и активно провести время всей семьёй? Похвально! " +
      "Приезжайте к нам все вместе и не забудьте позвать друзей. На равных сможете " +
      "повеселиться от мала до велика и дополнительно упрочить семейный и дружеские узы.",
    image: require("@/assets/img/services/family/26.jpg"),
    slides: [
      require("@/assets/img/services/family/1.jpg"),
      require("@/assets/img/services/family/3.jpg"),
      require("@/assets/img/services/family/4.jpg"),
      require("@/assets/img/services/family/5.jpg"),
      require("@/assets/img/services/family/6.jpg"),
      require("@/assets/img/services/family/7.jpg"),
      require("@/assets/img/services/family/8.jpg"),
      require("@/assets/img/services/family/9.jpg"),
      require("@/assets/img/services/family/10.jpg"),
      require("@/assets/img/services/family/11.jpg"),
      require("@/assets/img/services/family/12.jpg"),
      require("@/assets/img/services/family/13.jpg"),
      require("@/assets/img/services/family/14.jpg"),
      require("@/assets/img/services/family/15.jpg"),
      require("@/assets/img/services/family/16.jpg"),
      require("@/assets/img/services/family/17.jpg"),
      require("@/assets/img/services/family/18.jpg"),
      require("@/assets/img/services/family/19.jpg"),
      require("@/assets/img/services/family/20.jpg"),
      require("@/assets/img/services/family/21.jpg"),
      require("@/assets/img/services/family/22.jpg"),
      require("@/assets/img/services/family/23.jpg"),
      require("@/assets/img/services/family/24.jpg"),
      require("@/assets/img/services/family/25.jpg"),
      require("@/assets/img/services/family/26.jpg"),
    ],
    is_main: true,
    price: 1500,
    discount: 33.3,
    video: null,
  },
  {
    id: 2,
    page: "lazertag-dlya-detey",
    title: "Детский лазертаг",
    price: 1500,
    discount: 33.3,
    description: `Весело, компанией до 30 человек с комфортом поиграть в лазертаг, а потом съесть тортик и попить чай в центре города? Не проблема, добро пожаловать!
<br /><br />
В стоимость входят:
<ul>
<li>Заботливый и внимательный персонал, который проведёт инструктаж и будет с вами на протяжении всего посещения.</li>
<li>Аренда игровой арены.</li>
<li>Прокат индивидуального лазертаг-оборудования: <a href='https://vk.com/bangbangsar?z=album-205196470_279872477'>тагер</a> на выбор + повязка с датчиками попадания.</li>
<li>2 часа захватывающих эмоций.</li>
<li>Просторная и уютная комната отдыха.</li>
</ul>`,
    promo_description: `Весело, компанией до 30 человек с комфортом поиграть в лазертаг, а потом съесть тортик и попить чай в центре города? Не проблема, добро пожаловать!`,
    image: require("@/assets/img/services/childs/2.jpg"),
    slides: [
      require("@/assets/img/services/childs/1.jpg"),
      require("@/assets/img/services/childs/2.jpg"),
      require("@/assets/img/services/childs/3.jpg"),
      require("@/assets/img/services/childs/4.jpg"),
      require("@/assets/img/services/childs/5.jpg"),
      require("@/assets/img/services/childs/6.jpg"),
      require("@/assets/img/services/childs/7.jpg"),
      require("@/assets/img/services/childs/8.jpg"),
      require("@/assets/img/services/childs/9.jpg"),
      require("@/assets/img/services/childs/10.jpg"),
      require("@/assets/img/services/childs/11.jpg"),
      require("@/assets/img/services/childs/12.jpg"),
      require("@/assets/img/services/childs/13.jpg"),
      require("@/assets/img/services/childs/14.jpg"),
      require("@/assets/img/services/childs/15.jpg"),
      require("@/assets/img/services/childs/16.jpg"),
      require("@/assets/img/services/childs/17.jpg"),
      require("@/assets/img/services/childs/18.jpg"),
      require("@/assets/img/services/childs/19.jpg"),
      require("@/assets/img/services/childs/20.jpg"),
      require("@/assets/img/services/childs/21.jpg"),
      require("@/assets/img/services/childs/22.jpg"),
      require("@/assets/img/services/childs/23.jpg"),
      require("@/assets/img/services/childs/24.jpg"),
      require("@/assets/img/services/childs/25.jpg"),
      require("@/assets/img/services/childs/26.jpg"),
      require("@/assets/img/services/childs/27.jpg"),
      require("@/assets/img/services/childs/28.jpg"),
      require("@/assets/img/services/childs/29.jpg"),
      require("@/assets/img/services/childs/30.jpg"),
      require("@/assets/img/services/childs/31.jpg"),
      require("@/assets/img/services/childs/32.jpg"),
      require("@/assets/img/services/childs/33.jpg"),
    ],
    is_main: false,
    video: null,
  },
  {
    id: 3,
    page: "viezdnaya-igra",
    title: "Выездная лазертаг игра",
    price: 2500,
    discount: 40,
    description: `Проведи время с пользой!
<br /><br />
Организация и проведение игры в лазертаг на любой из свободно и согласовано доступных площадок, таких как:
Лес или его часть
Двор, спортивные площадки
Ваша собственная территория
<br /><br />
В стоимость входят:
<ul>
<li>Заботливый и внимательный персонал, который проведёт инструктаж и будет с вами на протяжении всего посещения.</li>
<li>Аренда игровой арены.</li>
<li>Прокат индивидуального лазертаг-оборудования: <a href='https://vk.com/bangbangsar?z=album-205196470_279872477'>тагер</a> на выбор + повязка с датчиками попадания.</li>
<li>2 часа захватывающих эмоций.</li>
<li>Просторная и уютная комната отдыха.</li>
</ul>`,
    promo_description: `Проведи время с пользой!

Организация и проведение игры в лазертаг на любой из свободно и согласовано доступных площадок, таких как:
Лес или его часть
Двор, спортивные площадки
Ваша собственная территория`,
    image: require("@/assets/img/services/open/5.jpg"),
    slides: [
      require("@/assets/img/services/open/1.jpg"),
      require("@/assets/img/services/open/2.jpg"),
      require("@/assets/img/services/open/3.jpg"),
      require("@/assets/img/services/open/4.jpg"),
      require("@/assets/img/services/open/5.jpg"),
      require("@/assets/img/services/open/7.jpg"),
      require("@/assets/img/services/open/8.jpg"),
      require("@/assets/img/services/open/9.jpg"),
      require("@/assets/img/services/open/10.jpg"),
      require("@/assets/img/services/open/11.jpg"),
      require("@/assets/img/services/open/12.jpg"),
    ],
    is_main: false,
    video: null,
  },
  {
    id: 4,
    title: "Открытая лазертаг тренировка",
    page: "otkritie-trenirovki",
    price: 1000,
    discount: 50,
    description: `В стоимость входят:
<ul>
<li>Приветливая команда, которая проведёт инструктаж и будет делиться опытом игры на протяжении всей тренировки.</li>
<li>Прокат индивидуального лазертаг-оборудования: <a href='https://vk.com/bangbangsar?z=album-205196470_279872477'>тагер</a> на выбор + повязка с датчиками попадания.</li>
<li>2 часа захватывающих эмоций.</li>
</ul>`,
    promo_description: `Весело, компанией до 30 человек с комфортом поиграть в лазертаг, а потом съесть тортик и попить чай в центре города? Не проблема, добро пожаловать!`,
    image: require("@/assets/img/services/anaconda/1.jpg"),
    slides: [
      require("@/assets/img/services/anaconda/1.jpg"),
      require("@/assets/img/services/anaconda/2.jpg"),
      require("@/assets/img/services/anaconda/3.jpg"),
      require("@/assets/img/services/anaconda/4.jpg"),
      require("@/assets/img/services/anaconda/5.jpg"),
      require("@/assets/img/services/anaconda/6.jpg"),
      require("@/assets/img/services/anaconda/7.jpg"),
      require("@/assets/img/services/anaconda/8.jpg"),
      require("@/assets/img/services/anaconda/9.jpg"),
      require("@/assets/img/services/anaconda/10.jpg"),
      require("@/assets/img/services/anaconda/11.jpg"),
      require("@/assets/img/services/anaconda/12.jpg"),
    ],
    is_main: false,
    video: null,
  },
  {
    id: 5,
    title: "Лазертаг тренировка своей командой",
    page: "trenirovka",
    price: 1000,
    discount: 50,
    description: `Если у тебя есть своя лазертаг команда, но негде и не с чем тренироваться, приходите к нам! Командой от 8 человек вы сможете арендовать на час нашу арену и оборудование. А если захотите арендовать на два часа, то обойдётся всего 400 рублей с человека!
<br /><br /> В стоимость входят:
<ul>
<li>Приветливая команда, которая проведёт инструктаж и будет делиться опытом игры на протяжении всей тренировки.</li>
<li>Прокат индивидуального лазертаг-оборудования: <a href='https://vk.com/bangbangsar?z=album-205196470_279872477'>тагер</a> на выбор + повязка с датчиками попадания.</li>
<li>2 часа захватывающих эмоций.</li>
</ul>`,
    promo_description: `Если у тебя есть своя лазертаг команда, но негде и не с чем тренироваться, приходите к нам! Командой от 8 человек вы сможете арендовать на час нашу арену и оборудование. А если захотите арендовать на два часа, то обойдётся всего 400 рублей с человека!`,
    image: require("@/assets/img/services/command/5.jpg"),
    slides: [
      require("@/assets/img/services/command/1.jpg"),
      require("@/assets/img/services/command/2.jpg"),
      require("@/assets/img/services/command/3.jpg"),
      require("@/assets/img/services/command/4.jpg"),
      require("@/assets/img/services/command/5.jpg"),
      require("@/assets/img/services/command/6.jpg"),
      require("@/assets/img/services/command/7.jpg"),
      require("@/assets/img/services/command/8.jpg"),
      require("@/assets/img/services/command/9.jpg"),
      require("@/assets/img/services/command/10.jpg"),
      require("@/assets/img/services/command/11.jpg"),
    ],
    is_main: false,
    video: null,
  },
];

const get_service_from_page = function (page) {
  for (let i in SITE_SERVICES) {
    if (SITE_SERVICES[i].page === page) {
      return SITE_SERVICES[i];
    }
  }
  return null;
};

const get_service_by_id = function (id) {
  for (let i in SITE_SERVICES) {
    if (parseInt(SITE_SERVICES[i].id) === parseInt(id)) {
      return SITE_SERVICES[i];
    }
  }
  return null;
};

export { SITE_SERVICES, get_service_from_page, get_service_by_id };
